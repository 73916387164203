import React from "react";

const Header = () => {
    return (
        <div className="header">
            <h1>Space Launch</h1>
        </div>
    );
}

export default Header;

